import React, { useState, useEffect } from 'react';
import PlaygroundCard from './PlaygroundCard';
import sanityClient from '../../../client'


const Playground = ({title}) => {

  const [plays, setPlays] = useState(null);

  useEffect(() => {
    sanityClient.fetch(`*[_type == 'playground']{
      title,
      sourceURL,
      liveURL,
      "tags": tags[]->title,
    }`)
    .then((data) => setPlays(data))
    .catch(console.error)
  }, [])

  return (
    <section id="playground">
      <div className="sectionTitle"><span>{title}</span></div>
      <div className="playground__inner">
      {plays && plays.map((play, i) => (
          <PlaygroundCard
          key={i}
          tags={play.tags}
          title={play.title}
          source={play.sourceURL}
          live={play.liveURL}
          />
      ))}
      </div>
    </section>
  );
}

export default Playground;

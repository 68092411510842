import React, { useState, useEffect } from 'react';

import Projects from './components/Projects';
import Playground from './components/Playground';
import Contact from './components/Contact';
import Footer from './components/Footer';

import ProjectData from "../../ProjectData";
import PlaygroundData from "../../PlaygroundData";


const Things = () => {
  useEffect(() => {
    fetchProjects();
    fetchPlays();
  }, []);

  const [projects, setProjects] = useState([]);
  const [plays, setPlays] = useState([]);

  const fetchProjects = () => {
    setProjects(ProjectData);
  };

  const fetchPlays = () => {
    setPlays(PlaygroundData);
  };

    return (
        <div className="things">
        <Projects projects={projects} title="Projects"/>
        <Playground plays={plays} title="Playground"/>
        <Contact title="Contact"/>
        <Footer/>
      </div>
    );
}

export default Things;

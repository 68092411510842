import React, { useState, useEffect } from 'react';
import ProjectCard from './ProjectCard';
import sanityClient from '../../../client'

import image from "../../../images/rb.jpg"

const Projects = ({title}) => {
  

  const [projects, setProjects] = useState(null);

  useEffect(() => {
    sanityClient.fetch(`*[_type == 'project']{
      title,
      slug,
      summary,
      mainImage{
        asset->{
          _id,
          url
        },
        alt
      },
      liveURL,
      sourceURL,
      "tags": tags[]->title,
    }`)
    .then((data) => setProjects(data))
    .catch(console.error)
  }, [])



  return (
    <section id="projects">
      <div className="sectionTitle"><span>{title}</span></div>
      <div className="projects__inner">
      {projects && projects.map(project => (
          <ProjectCard
          key={project.id}
          tags={project.tags}
          title={project.title}
          text={project.summary}
          live={project.liveURL}
          source={project.sourceURL}
          img={project.mainImage.asset.url}/>
      ))}
     
          
      </div>
    </section>
  );
}

export default Projects;

import React, { useEffect, useRef, useState } from "react";
import "./App.scss";
import "./custom/cursor.css";
import classNames from "classnames";

// import Tachyons from 'tachyons/css/tachyons.min.css'

import About from "./sections/About/About";
import Things from "./sections/Things/Things";
import Intro from "./sections/Intro/Intro";
import gsap from "gsap";

function App() {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [hidden, setHidden] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [linkHovered, setLinkHovered] = useState(false);

  useEffect(() => {
    introAnimation();
    addEventListeners();
    handleLinkHoverEvents();
    return () => removeEventListeners();
  }, []);

  const introAnimation = () => {
    const tl = gsap.timeline({ defaults: { ease: "power1.out" } });
    tl.to(".text", { y: "0%", duration: 1, delay: 0.5 });
    tl.to(".slider", { y: "-100%", duration: 1, delay: 0 });
    tl.to(".site-intro", { y: "-100%", duration: 1 }, "-=1");
    tl.fromTo(".about", { opacity: 0 }, { opacity: 1, duration: 2 });
    tl.fromTo(".things", { opacity: 0 }, { opacity: 1, duration: 2 }, "-=2");
  };

  const addEventListeners = () => {
    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseenter", onMouseEnter);
    document.addEventListener("mouseleave", onMouseLeave);
    document.addEventListener("mousedown", onMouseDown);
    document.addEventListener("mouseup", onMouseUp);
  };

  const removeEventListeners = () => {
    document.removeEventListener("mousemove", onMouseMove);
    document.removeEventListener("mouseenter", onMouseEnter);
    document.removeEventListener("mouseleave", onMouseLeave);
    document.removeEventListener("mousedown", onMouseDown);
    document.removeEventListener("mouseup", onMouseUp);
  };

  const onMouseMove = (e) => {
    setPosition({ x: e.clientX, y: e.clientY });
  };

  const onMouseLeave = () => {
    setHidden(true);
  };

  const onMouseEnter = () => {
    setHidden(false);
  };

  const onMouseDown = () => {
    setClicked(true);
  };

  const onMouseUp = () => {
    setClicked(false);
  };

  const handleLinkHoverEvents = () => {
    document
      .querySelectorAll(
        "a, .projects__inner, .playground__inner, #contact article"
      )
      .forEach((el) => {
        el.addEventListener("mouseover", () => setLinkHovered(true));
        el.addEventListener("mouseout", () => setLinkHovered(false));
      });
  };

  const cursorClasses = classNames("cursor", {
    "cursor--hidden": hidden,
    "cursor--clicked": clicked,
    "cursor--link-hovered": linkHovered,
  });

  const isMobile = () => {
    const ua = navigator.userAgent;
    return /Android|Mobi/i.test(ua);
  };

  return (
    <div className="main">
      {typeof navigator !== "undefined" && isMobile() ? null : (
        <div
          className={cursorClasses}
          style={{ left: `${position.x}px`, top: `${position.y}px` }}
        />
      )}

      <About />
      <Things />

      <Intro />
    </div>
  );
}

export default App;

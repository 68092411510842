import React from "react";

const ProjectCard = ({ tags, title, text, live, source, img }) => {

  return (
    <article className="article">
      <a href={live} target="_blank">
        <div>
        <p className="tech">
          {tags.map((tag, i) => (
                    <span key={i}>{tag} </span>
                    ))}</p>
          <h3>{title}</h3>
          
          <p className="text">{text}</p>
          
          <div className="buttons">
            <a href={live} target="_blank">
              Live Site
            </a>
            <a href={source} target="_blank">
              Source Code
            </a>
          </div>
        </div>
        <div className="projectImg">
          <img src={img} alt="" srcset="" />
        </div>
      </a>
    </article>
  );
};

export default ProjectCard;

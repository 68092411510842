import React from 'react';
import Beard from '../../../images/richard-icon.svg';

const Footer = () => {
    return (
        <section id="footer">
          <div><img src={Beard} alt="" srcset=""/><p>Created by Richard Butler<span style={{fontSize: "smaller", margin: "0 0.3rem"}}>©</span> 2020</p></div>
        </section>
    );
}

export default Footer;

import React, { useEffect } from "react";
import "./Intro.scss";

export default function Intro() {
  return (
    <>
      <div className="site-intro">
        <div className="intro-text">
          <h1 className="hide">
            <span className="text">richardbutler.dev</span>
          </h1>
        </div>
      </div>
      <div className="slider"></div>
    </>
  );
}

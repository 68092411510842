import React from 'react';
import {Link, animateScroll as scroll} from "react-scroll";

const Nav = () => {
    return (
        <nav>
            <ul>
              <li>
                <Link
                  className="navLink"
                  activeClass="active"
                  to="projects"
                  spy={true}
                  smooth={true}
                  offset={-88}
                  duration={500}>
                  <span className="nav__number">01</span>
                  <span className="nav__line"></span>
                  <span className="nav__text">Projects</span>
                </Link>
              </li>
              <li>
                <Link
                  className="navLink"
                  activeClass="active"
                  to="playground"
                  spy={true}
                  smooth={true}
                  offset={-88}
                  duration={500}>
                  <span className="nav__number">02</span>
                  <span className="nav__line"></span>
                  <span className="nav__text">Playground</span>
                </Link>
              </li>
              <li>
                <Link
                  className="navLink"
                  activeClass="active"
                  to="contact"
                  spy={true}
                  smooth={true}
                  offset={-80}
                  duration={500}>
                  <span className="nav__number">03</span>
                  <span className="nav__line"></span>
                  <span className="nav__text">Contact</span>
                </Link>
              </li>
            </ul>
          </nav>
    );
}

export default Nav;

import React from 'react';

import {FaLinkedin} from 'react-icons/fa';
import {FaGithub} from 'react-icons/fa';

import Profile from '../../../images/profile.jpg';

const Social = () => {
    return (
        <div className="about__social">
          <div className="profileImg"><img src={Profile} alt="" srcset=""/></div>
          <ul>
            <li>
              <a href="https://www.linkedin.com/in/richard-butler-6845b178/" target="_blank">
                <FaLinkedin/>
                <span>LinkedIn</span>
              </a>
            </li>
            <li>
              <a href="https://github.com/Jets0m" target="_blank">
                <FaGithub/>
                <span>GitHub</span>
              </a>
            </li>
          </ul>
        </div>
    );
}

export default Social;

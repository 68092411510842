import React, { useState, useEffect } from 'react';
import sanityClient from '../../../client'
import imageUrlBuilder from "@sanity/image-url"
import BlockContent from "@sanity/block-content-to-react"

import Profile from '../../../images/profile.jpg';

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source)
}

const Intro = () => {

  const [profile, setProfile] = useState(null);

useEffect(() => {
  sanityClient.fetch(`*[_type == 'author']{
    name,
    bio,
    "authorImage": image.asset->url
  }`)
  .then((data) => setProfile(data[0]))
  .catch(console.error)
}, [])

if (!profile)  return <div></div>

    return (
        <div className="intro">
            <h1 className="heading-1">Hello, I'm {profile.name}.</h1>
            <div className="leading">
              <div className="profileImg"><img src={urlFor(profile.authorImage).url()} alt="" srcset=""/></div>
              <BlockContent 
              blocks={profile.bio}
              projectId="4zc67xrx"
              dataset="production"
              />
            </div>
          </div>
    );
}

export default Intro;

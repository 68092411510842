import React from 'react';

import Intro from "./components/Intro";
import Nav from './components/Nav';
import Social from './components/Social';


const About = () => {
    return (
        <div className="about">
        <div className="about__main">
          <Intro/>
          <Nav/>
        </div>
        <Social/>
      </div>
    );
}

export default About;

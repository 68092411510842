import React from 'react';

const PlaygroundCard = ({tags, title, source, live}) => {
  return (
    <article>
      <a href={live} target="_blank">
        <div className="article__inner">
          <p className="tech">{tags && tags.map((tag, i) => (<span key={i}>{tag} </span>))}</p>
          <div>
            <h3>{title}</h3>
            <div className="buttons">
              <a href={source} target="_blank">Source Code</a>
            </div>
          </div>
        </div>
      </a>
    </article>
  );
}

export default PlaygroundCard;

const PlaygroundData = [
  {
    title: "ThreeJS landing page",
    date: "2020",
    tech: ["Three JS", "Javascript", "HTML", "CSS"],
    text: "ThreeJS landing page",
    live: "https://jets0m.github.io/theearthproject/",
    source: "https://github.com/Jets0m/theearthproject",
    id: "1",
  },
  {
    title: "Recipe Social App",
    date: "2020",
    tech: ["React", "Firebase", "Material UI"],
    text: "Recipe Social App",
    live: "https://social-media-app-23092.web.app/",
    source: "https://github.com/Jets0m/",
    id: "2",
  },
  {
    title: "Expense Tracker",
    date: "2020",
    tech: ["React", "MongoDB", "Node JS", "Express"],
    text: "MERN project",
    live: "https://expensetracker-richardbutler.herokuapp.com/",
    source: "https://github.com/Jets0m/expensetracker",
    id: "3",
  },

  {
    title: "Messenger App",
    date: "2020",
    tech: ["React", "Firebase", "Material UI"],
    text: "Messenger App",
    live: "https://messenger-34452.web.app/",
    source: "https://github.com/Jets0m/messenger-app",
    id: "4",
  },
];

export default PlaygroundData;

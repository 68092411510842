const ProjectData = [{
    "title": "Deers-Leap Insight",
    "date": "2020",
    "tech": ["HTML", "CSS", "Javascript"],
    "text": "Responsive one page website including custom SVG logo animation",
    "live": "https://deers-leap.com",
    "source": "https://github.com/Jets0m/deers-leap-insight",
    "img": "https://i.ibb.co/12MJ6sW/dl.jpg",
    "id": "1"
  },
  {
    "title": "Atlantic Lookout",
    "date": "2020",
    "tech": ["HTML", "CSS", "Javascript"],
    "text": "Responsive one page website including gallery, testimonial slider and integration with a third-party booking agent.",
    "live": "http://atlanticlookout.com",
    "source": "https://github.com/Jets0m/atlanticlookout",
    "img": "https://i.ibb.co/MNN0ZzB/al.jpg",
    "id": "2"
  },
  // {
  //   "title": "Rebecca McCarthy Portfolio",
  //   "date": "2020",
  //   "tech": ["HTML", "CSS", "Javascript", "REACT"],
  //   "text": "Portoflio project for 3D designer and visuliser using React JS, React Router and HTML5 video.",
  //   "live": "https://jets0m.github.io/rebeccamccarthy/",
  //   "source": "https://github.com/Jets0m/rebeccamccarthy",
  //   "img": "https://i.ibb.co/CWB8rXJ/rm.jpg",
  //   "id": "3"
  // },
  // {
  //   "title": "Richard Butler Portfolio",
  //   "date": "2020",
  //   "tech": ["HTML", "CSS", "Javascript", "REACT"],
  //   "text": "Personal portoflio project using React JS and React Hooks. ",
  //   "live": "https://richardbutler.dev",
  //   "source": "https://github.com/Jets0m/React-Portfolio",
  //   "img": "https://i.ibb.co/NyVjcGR/rb.jpg",
  //   "id": "4"
  // }
]

export default ProjectData;
import React from 'react';

import {FaEnvelope} from 'react-icons/fa';
import {FaPhoneAlt} from 'react-icons/fa';
import {BsChatDotsFill} from 'react-icons/bs';

const Contact = ({title}) => {
    return (
        <section id="contact">
          <div className="sectionTitle"><span>{title}</span></div>
          <article>
            <a href="mailto:contact@richardbutler.dev" target="_blank">
              <div className="contact__icon"><FaEnvelope/></div>
              <div className="text">
                <p className="contact__route">Email</p>
                <h3>contact<wbr/>@richardbutler.dev</h3>
              </div>
            </a>
          </article>
          <article>
            <a href="tel:07828744003" target="_blank">
              <div className="contact__icon"><FaPhoneAlt/></div>
              <div className="contact__text">
                <p className="contact__route">Phone</p>
                <h3>+44(0) 782 874 4003</h3>
              </div>
            </a>
          </article>
          <article>
            <a href="https://www.linkedin.com/in/richard-butler-6845b178/" target="_blank">
              <div className="contact__icon"><BsChatDotsFill/></div>
              <div className="contact__text">
                <p className="contact__route">Social</p>
                <h3>LinkedIn</h3>
              </div>
            </a>
          </article>
        </section>
    );
}

export default Contact;
